

var InitAccordeon = function(){

    var accTab = [];

    $(".bloc-accordeon").each(function( index ){

        accTab[index] = $(this);

        // Init default
        accTab[index].find('.accordeon').eq(0).addClass('opened').next('.panel').addClass('opened');

        accTab[index].find('.accordeon').click( function() {

            $(this).toggleClass('opened');
            accTab[index].find('.accordeon').not( $(this) ).removeClass('opened');

            accTab[index].find('.panel').removeClass('opened');
            accTab[index].find('.accordeon.opened').next('.panel').addClass('opened');

        });

    });

};