tarteaucitron.init({
    "privacyUrl": "", /* Privacy policy url */
    "hashtag": "#rgpd", /* Open the panel with this hashtag */
    "cookieName": "tarteaucitron", /* Cookie name */
    "orientation": "bottom", /* Banner position (top - bottom) */
    "showAlertSmall": false, /* Show the small banner on bottom right */
    "cookieslist": false, /* Show the cookie list */
    "adblocker": false, /* Show a Warning if an adblocker is detected */
    "DenyAllCta": true, /* Show the deny all button */
    "AcceptAllCta": true, /* Show the accept all button when highPrivacy on */
    "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
    "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */
    "removeCredit": true, /* Remove credit link */
    "moreInfoLink": true, /* Show more info link */
    "useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */
    "useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */
    //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */
    "readmoreLink": "", /* Change the default readmore link */
    "mandatory": true, /* Show a message about mandatory cookies */
});


// Ajouter ici tous les services susceptibles de déposer des cookies (cf https://opt-out.ferank.eu/fr/install/)
// pour les services nécéssitants des modifs du code html, se referer à la classe ThuriaAuCitron

// Google map (initialisé dans th_maps)

// analytics
// tarteaucitron.user.gtagUa = 'UA-117118653-1';
// tarteaucitron.user.gtagMore = function () { /* add here your optionnal gtag() */ };
// (tarteaucitron.job = tarteaucitron.job || []).push('gtag');

tarteaucitron.user.googletagmanagerId = 'GTM-5868F2H';
(tarteaucitron.job = tarteaucitron.job || []).push('googletagmanager');

// Médias
(tarteaucitron.job = tarteaucitron.job || []).push('youtube');
(tarteaucitron.job = tarteaucitron.job || []).push('vimeo');
(tarteaucitron.job = tarteaucitron.job || []).push('dailymotion');


function CitronVideoHtml(id, plateforme, autoplay, mute) {
    return '<div class="tac_player ' + plateforme + '_player" videoID="' + id + '" width="1280px" theme="dark" height="auto" showinfo="0" controls="1" rel="0" autoplay=' + autoplay +'" mute="' + mute + '"></div>';
}
