class headerVideo{
    bouton = {
        classe: null,
        element: null
    };

    modale = {
        classe: null,
        element: null
    };

    //open-video-modale && video-modale
    constructor(bouton, modale){
        if (!bouton || !modale){
             return null;
        }

        this.bouton.classe = bouton;
        this.bouton.element = document.querySelectorAll(this.bouton.classe)[0];

        this.modale.classe = modale;
        this.modale.element = document.querySelectorAll(this.modale.classe)[0];

        if (typeof this.modale.element === 'undefined' || typeof this.bouton.element === 'undefined'){
            return null;
        }
        console.log('headervideo');
        this.listen();
    }

    listen(){
        // Ecoute de l'ouverture de la modale
        this.bouton.element.addEventListener('click', (event) => {
            event.preventDefault();
            this.openModale();
        });

        // Ecoute de la fermeture de la modale
        this.modale.element.querySelectorAll('.close')[0].addEventListener('click', (event) => {
            event.preventDefault();
            this.closeModale();
        })
    }

    openModale(){
        this.modale.element.classList.add("active");
        document.getElementById('main').classList.add("modale-open");
        th_video.init();
    }

    closeModale(){
        this.modale.element.classList.remove("active");
        document.getElementById('main').classList.remove("modale-open");

        this.modale.element.classList.remove('iframe-loaded');
        this.modale.element.querySelectorAll('.embed-container')[0].innerHTML = '';
    }

}
new headerVideo('.open-video-modale', '.video-modale');