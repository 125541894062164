

function isIE () {
    var myNav = navigator.userAgent.toLowerCase();
    return (myNav.indexOf('msie') != -1) ? 'isie' : 'noie';
}

function isIEVersion () {
    var myNav = navigator.userAgent.toLowerCase();
    return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
}

//event listener: DOM ready
function addLoadEvent(func) {
    var oldonload = window.onload;
    if (typeof window.onload != 'function') { window.onload = func;
    } else {
        window.onload = function() {
            if (oldonload) { oldonload(); }
            func();
        };
    }
}

//call plugin function after DOM ready
addLoadEvent(function() {

    loadMaps();

    if( $('html.ie9').length > 0 ){

        // init ObjectFit
        objectFitImages();

    }

    // init slider
    owlSliderListing();

    fixHeight();

});


// Store the mouseMove Interval
var mouseMove;

$(function() {

    jQuery('html').addClass( isIE() );
    if( isIE() && isIEVersion() == 9) { jQuery('html').addClass('ie9'); }

    InitSelect();
    InitCheckbox();
    InitDatepicker();
    InitAccordeon();

    // Menu Sticky
    sticky();

    // Init Zoombox
    $('a.zoombox').zoombox();

    // Next & Prev button animation
    if( $('.bloc-navigation-event').length > 0 ){

        $(document).mousemove(function( event ) {

            clearInterval( mouseMove );
            $('.bloc-navigation-event').removeClass('noShow');

            mouseMove = setInterval(function(){
                $('.bloc-navigation-event').addClass('noShow');
            }, 4000 );

        });

    }

});

// Lazyload
var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
});