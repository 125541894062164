
var sticky = function(){

    // force Sticky
    if( $('#header.force-sticky').length > 0 ){
        var hSt = parseInt($('#header #navigation').height());
        $('#header').css({ 'height' : hSt + 'px', 'min-height' : hSt + 'px' });
    }else{

        if( $('body.page-template-page-accueil').length > 0 ){
            if( $('body.page-template-page-accueil .bloc-slider-homepage').length == 0 ){

                $('#header').addClass('force-sticky');

            }
        }

        if( $('body.page-template-page-eventlisting').length > 0 ){
            if( $('body.page-template-page-eventlisting .bloc-slider-events').length == 0 ){

                $('#header').addClass('force-sticky');

            }
        }

    }

    var startPos = 0, startPosSidebar = 0, endPosSidebar = 0, scrollTop = 0;
    var domStick = $('#header'), domStickSidebar = $('#stickySidebar'), domStickArticle = $('#container-article');
    var $window = $(window), $document = $(document);

    var scroller = function(){

        startPos = parseInt( domStick.height() ) + 20;
        scrollTop =  parseInt( $window.scrollTop() );

        if( scrollTop > startPos ){ domStick.addClass('is-sticky');
        }else{ domStick.removeClass('is-sticky'); }

        if ( parseInt($(window).innerWidth()) > 850 && $("html.desktop").length > 0 ) {

            if( $('#stickySidebar').length > 0 ){

                containerH = parseInt( domStickSidebar.parent().height() );
                sidebarH = parseInt( domStickSidebar.find('.sticky-inner').outerHeight() );
                screenHeight = parseInt( $window.height() );

                domStickArticle.css({ 'min-height' : sidebarH + 'px' });

                var offsetObj = domStickSidebar.parent().offset();
                var offset = parseInt(offsetObj.top);

                var positionTop = parseInt( domStick.height() );
                var widthSticky = parseInt( domStickSidebar.width() );
                if ( parseInt($(window).innerWidth()) > 650 ) { var marginTop = 40; } else { var marginTop = 20; }

                screenHeight = screenHeight - marginTop - positionTop;

                domStickSidebar.find('.sticky-inner').css({ 'width' : widthSticky + 'px' });

                startPosSidebar = offset - positionTop - marginTop;
                endPosSidebar = parseInt( parseInt( containerH - sidebarH ) + startPosSidebar );

                if( scrollTop >= startPosSidebar && scrollTop <= endPosSidebar && containerH >= sidebarH && sidebarH <= screenHeight ){

                    $('html').addClass('is-sticky-sidebar');
                    domStickSidebar.find('.sticky-inner').css({ 'top' : positionTop + marginTop + 'px' });
                    $('html').removeClass('ToBottom');

                }else if( scrollTop >= startPosSidebar && scrollTop >= endPosSidebar && containerH >= sidebarH && sidebarH <= screenHeight ){

                    $('html').addClass('is-sticky-sidebar');
                    $('html').addClass('ToBottom');

                }else{

                    $('html').removeClass('is-sticky-sidebar');
                    $('html').removeClass('ToBottom');

                }

            }

        }else{

            $('html').removeClass('is-sticky-sidebar');
            $('html').removeClass('ToBottom');

        }

    };

    scroller();


    if (window.addEventListener) { window.addEventListener('scroll', scroller, false);
    } else if (window.attachEvent) { window.attachEvent('onscroll', scroller); }

    /*   Aux changements d'orientation de la page
     * -------------------------------------------------- */

    if ( window.addEventListener ) {
        window.addEventListener("orientationchange", function () {

            // Fix pour tablettes
            setTimeout(function () {

                scroller();

                // force Sticky
                if( $('#header.force-sticky').length > 0 ){
                    var hSt = parseInt($('#header #navigation').height());
                    $('#header').css({ 'height' : hSt + 'px', 'min-height' : hSt + 'px' });
                }

            }, 50);

        }, false);
    }

    // Store the window width
    var windowWidth = parseInt( $(window).width() );


    /*   Aux redimensionements de la page
     * -------------------------------------------------- */
    $(window).resize(function(){

        // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
        if ($(window).width() != windowWidth) {

            // Fix pour tablettes
            setTimeout(function () {

                scroller();

                // force Sticky
                if( $('#header.force-sticky').length > 0 ){
                    var hSt = parseInt($('#header #navigation').height());
                    $('#header').css({ 'height' : hSt + 'px', 'min-height' : hSt + 'px' });
                }
                // Update the window width for next time
                windowWidth = $(window).width();

            }, 50);

        }

    });

};
