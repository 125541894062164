
/*   Ouverture et Fermeture du layer
 * -------------------------------------------------- */

$(".open-layer").click(function(e) {

    $("#overlay").addClass('opened');
    $("html").addClass('stop-scroll-overlay');

});

$(".close-layer").click(function(e) {

    $("#overlay .search-form").show();
    $("#overlay .overlay_form_wrapper").hide();

    $("#overlay").removeClass('opened');
    $("html").removeClass('stop-scroll-overlay');

});


$(".open-layer-form").click(function(e) {

    $("#overlay .search-form").hide();
    $("#overlay .overlay_form_wrapper").show();

    $("#overlay").addClass('opened');
    $("html").addClass('stop-scroll-overlay');

});

// Fermeture au clic sur le côté

if( $("html.desktop").length > 0 ){

    $(document).on( "click", function(event){

        if( $(event.target)[0] == $('#overlay.opened')[0] ) {
            setTimeout(function(){
                $(".close-layer").click();
            }, 50);
        }

    });

}else{

    $(document).on( "touchend", function(event){

        if( $(event.target)[0] == $('#overlay.opened')[0] ) {
            setTimeout(function(){
                $(".close-layer").click();
            }, 50);
        }

    });

}