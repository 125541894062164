/**
 * Created by samuel on 07/03/2018.
 */

    var fixHeight = function() {


        if( $('.fixMini').length > 0 ){

            $('.fixMini').css('height', 'auto' );
            var fixMiniHeight = parseInt( $('.fixMini').height() ) + 1;
            if( fixMiniHeight < 400 ){ fixMiniHeight = 400; }
            $('.fixMini').css('height', fixMiniHeight + 'px' );

        }

        if( $('.fixHeight').length > 0 ) {

            // Fix à la hauteur de l'écran
            if( parseInt( $(window).width() ) > 650 ){

                var screenHeight = parseInt( $(window).height() ) + 1;

                $('.fixHeight').each(function ( index, element ) {
                    $(this).css('height', screenHeight + 'px' );
                });

            }else{

                $('.fixHeight').each(function ( index, element ) {
                    $(this).css('height', 'auto' );
                });

            }

        }

        if( $('.bloc-image').length > 0 ){
            $('.bloc-image').each(function(){
                $(this).find('figcaption').css( 'width' , $(this).height() );
            });
        }

        if( $('.bloc-slider-listing-post').length > 0 ){

            $('.bloc-slider-listing-post').each(function ( index, element ) {

                var maxHeight = maxHeighAuthor = maxHeightTitle = 0;

                $(this).find('.item .tags').css('height', 'auto' );
                $(this).find('.item .tags').each(function ( index, element ) {
                    if( maxHeight < parseInt( $(this).height() ) ){ maxHeight = parseInt( $(this).height() ); }
                });
                $(this).find('.item .tags').css('height', maxHeight + 'px' );

                $(this).find('.item .author').css('height', 'auto' );
                $(this).find('.item .author').each(function ( index, element ) {
                    if( maxHeighAuthor < parseInt( $(this).height() ) ){ maxHeighAuthor = parseInt( $(this).height() ); }
                });
                $(this).find('.item .author').css('height', maxHeighAuthor + 'px' );

                $(this).find('.item h1').css('height', 'auto' );
                $(this).find('.item h1').each(function ( index, element ) {
                    if( maxHeightTitle < parseInt( $(this).height() ) ){ maxHeightTitle = parseInt( $(this).height() ); }
                });
                $(this).find('.item h1').css('height', maxHeightTitle + 'px' );

            });
        }

        if( $('.bloc-slider-listing-cms').length > 0 ){

            $('.bloc-slider-listing-cms').each(function ( index, element ) {

                var maxHeight = maxHeighAuthor = maxHeightTitle = 0;

                $(this).find('.item .tags').css('height', 'auto' );
                $(this).find('.item .tags').each(function ( index, element ) {
                    if( maxHeight < parseInt( $(this).height() ) ){ maxHeight = parseInt( $(this).height() ); }
                });
                $(this).find('.item .tags').css('height', maxHeight + 'px' );

                $(this).find('.item .author').css('height', 'auto' );
                $(this).find('.item .author').each(function ( index, element ) {
                    if( maxHeighAuthor < parseInt( $(this).height() ) ){ maxHeighAuthor = parseInt( $(this).height() ); }
                });
                $(this).find('.item .author').css('height', maxHeighAuthor + 'px' );

                $(this).find('.item h1').css('height', 'auto' );
                $(this).find('.item h1').each(function ( index, element ) {
                    if( maxHeightTitle < parseInt( $(this).height() ) ){ maxHeightTitle = parseInt( $(this).height() ); }
                });
                $(this).find('.item h1').css('height', maxHeightTitle + 'px' );

            });
        }

        if( $('#menu-footer').length > 0 ){

            $('#menu-footer').each(function ( index, element ) {

                var maxHeight = 0;

                $(this).find(' > .menu-item .h4').css('height', 'auto' );
                $(this).find(' > .menu-item .h4').each(function ( index, element ) {
                    if( maxHeight < parseInt( $(this).height() ) ){ maxHeight = parseInt( $(this).height() ); }
                });
                $(this).find(' > .menu-item .h4').css('height', maxHeight + 'px' );

            });
        }

        if( $('.bloc-cols-text').not('.deport-left').not('.deport-right').length > 0 ){

            $('.bloc-cols-text').not('.deport-left').not('.deport-right').each(function ( index, element ) {

                var maxHeight = maxHeightTitle = maxHeighAuthor = maxHeightTitleBis = 0;

                $(this).find('.item .tags').css('height', 'auto' );
                $(this).find('.item .tags').each(function ( index, element ) {
                    if( maxHeight < parseInt( $(this).height() ) ){ maxHeight = parseInt( $(this).height() ); }
                });
                $(this).find('.item .tags').css('height', maxHeight + 'px' );

                $(this).find('.item h1').css('height', 'auto' );
                $(this).find('.item h1').each(function ( index, element ) {
                    if( maxHeightTitle < parseInt( $(this).height() ) ){ maxHeightTitle = parseInt( $(this).height() ); }
                });
                $(this).find('.item h1').css('height', maxHeightTitle + 'px' );

                $(this).find('.item .author').css('height', 'auto' );
                $(this).find('.item .author').each(function ( index, element ) {
                    if( maxHeighAuthor < parseInt( $(this).height() ) ){ maxHeighAuthor = parseInt( $(this).height() ); }
                });
                $(this).find('.item .author').css('height', maxHeighAuthor + 'px' );

                $(this).find('.item h3').css('height', 'auto' );
                $(this).find('.item h3').each(function ( index, element ) {
                    if( maxHeightTitleBis < parseInt( $(this).height() ) ){ maxHeightTitleBis = parseInt( $(this).height() ); }
                });
                $(this).find('.item h3').css('height', maxHeightTitleBis + 'px' );

            });
        }


        $('.bloc-slider-listing-post.rooms').each(function ( index, element ) {

            var maxHeight = 0;
            maxHeight = parseInt( $(this).find('.inner-container > h2').outerHeight() );
            $(this).find('.before').css('height', maxHeight + 1 + 'px' );

        });

    };

    /*   Aux changements d'orientation de la page
     * -------------------------------------------------- */

    if ( window.addEventListener ) {
        window.addEventListener("orientationchange", function () {

            // Fix pour tablettes
            setTimeout(function () {

                fixHeight();

                if( $('.bloc-image').length > 0 ){
                    $('.bloc-image').each(function(){
                        $(this).find('figcaption').css( 'width' , $(this).height() );
                    });
                }

            }, 50);

        }, false);
    }

    // Store the window width
    var windowWidthfix = parseInt( $(window).width() );


    /*   Aux redimensionements de la page
     * -------------------------------------------------- */
    $(window).resize(function(){

        // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
        if ($(window).width() != windowWidthfix) {

            // Fix pour tablettes
            setTimeout(function () {

                fixHeight();

                if( $('.bloc-image').length > 0 ){
                    $('.bloc-image').each(function(){
                        $(this).find('figcaption').css( 'width' , $(this).height() );
                    });
                }

                // Update the window width for next time
                windowWidthfix = $(window).width();

            }, 50);

        }

    });