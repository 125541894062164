
var InitDatepicker = function(){

    var datepicker = [];

    var lang = document.documentElement.lang.substr(0,2);

    var i18n_trad = {
        en: {
            previousMonth   : 'Previous Month',
            nextMonth       : 'Next Month',
            months          : ['January','February','March','April','May','June','July','August','September','October','November','December'],
            weekdays        : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
            weekdaysShort   : ['S','M','T','W','T','F','S']
        },
        fr: {
            previousMonth   : 'Mois précédent',
            nextMonth       : 'Nächsten Monat',
            months          : ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
            weekdays        : ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
            weekdaysShort   : ['D','L','M','M','J','V','S']
        },
        de: {
            previousMonth   : 'Vorheriger Monat',
            nextMonth       : 'Volgende maand',
            months          : ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
            weekdays        : ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
            weekdaysShort   : ['S','M','D','M','D','F','S']
        },
        nl: {
            previousMonth   : 'Vorig maand',
            nextMonth       : 'Volgende maand',
            months          : ['Januari','Februari','Maart','April','Mei','Juni','Juli','Augustus','September','Oktober','November','December'],
            weekdays        : ['Zondag','Maandag','Dinsdag','Woensdag','Donderdag','Vrijdag','Zaterdag'],
            weekdaysShort   : ['Z','M','D','W','D','V','Z']
        }
    };

    $('.frm_forms .hasDatepicker input').each(function(i,e){

        var options = {
            field: $(this)[0],
            format: 'DD-MM-YYYY',
            showDaysInNextAndPreviousMonths: true
        };

        if( $('html.touchscreen').length > 0 ){

            $(this).attr('disabled','disabled');
            options.trigger = $(this).parent()[0];

        }

        if( lang in i18n_trad) {
            options.i18n = i18n_trad[ lang ];
        }

        datepicker[i] = new Pikaday(options);

        $( "#overlay .overlay_form_wrapper" ).scroll(function() {
            datepicker[i].hide()
        });

    });

};