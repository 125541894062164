
//event listener: DOM ready
function addLoadEvent(func) {
    var oldonload = window.onload;
    if (typeof window.onload != 'function') { window.onload = func;
    } else {
        window.onload = function() {
            if (oldonload) { oldonload(); }
            func();
        };
    }
}

//call plugin function after DOM ready
addLoadEvent(function() {

    // Browser language
    var browserlanguage = navigator.language || navigator.userLanguage;

    // Check Browser Version
    outdatedBrowser({
        bgColor: '#f25648',
        color: '#ffffff',
        lowerThan: 'boxShadow',
        languagePath: window.location.host + '/wp-content/themes/thuria/inc/outdatedbrowser/' + browserlanguage.substr(0,2).toLowerCase() + '.html'
    });

});
