/**
 * Created by samuel on 25/11/2016.
 */

// global var
var map = [];

var initMap = function(){

    if( $('.google-map-little').length > 0 ){

        var styledMapType = new google.maps.StyledMapType([
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e9e9e9"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dedede"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 36
                    },
                    {
                        "color": "#333333"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            }
        ]);

        $('.google-map-little').each( function( index, element ){

            $(this).css({'display':'block'});

            map[index] = [];
            map[index].TimeOutMap = '';

            map[index].mapOptions = {
                center: new google.maps.LatLng(0, 0),
                zoom: 12,
                scrollwheel: false,
                draggable: false,
                mapTypeControl: false,
                zoomControl: false,
                fullscreenControl: false,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_BOTTOM
                },
            };

            // Get Markers
            map[index].markers = [];
            map[index].markers = $(this).find('.marker');

            // Set Id to slider
            map[index].mapId = $(this).find('.inner-map').attr('id');

            if( map[index].mapId === undefined ) {

                $(this).find('.inner-map').attr('id', 'gmap_' + new Date().valueOf() );
                map[index].mapId = $(this).find('.inner-map').attr('id');

            }

            map[index].map = new google.maps.Map( document.getElementById( map[index].mapId ), map[index].mapOptions );

            map[index].map.mapTypes.set('styled_map', styledMapType);
            map[index].map.setMapTypeId('styled_map');

            // add a markers reference
            map[index].map.markers = [];

            // add markers
            map[index].markers.each(function(i){
                add_marker( $(this), map[index].map );
            });

            // center map on resize
            google.maps.event.addDomListener( window, "resize", function() {

                clearTimeout( map[index].TimeOutMap );
                map[index].TimeOutMap = setTimeout( function(){

                    // Center map Offset
                    center_map( map[index].map );

                }, 500);

            });

        });

    }

};


/**
 * Lance l'ajout du script maps
 */

function loadMaps() {
    if(typeof tarteaucitron != 'undefined') {
        tarteaucitron.user.googlemapsKey = 'AIzaSyAOkEkg5hlHRbvIXmr2wrar2unEmUyzGa0';
        tarteaucitron.user.mapscallback = 'initMap';
        (tarteaucitron.job = tarteaucitron.job || []).push('googlemaps');
    } else {
        appendScript('https://maps.googleapis.com/maps/api/js?v=3&libraries=places&key=AIzaSyAOkEkg5hlHRbvIXmr2wrar2unEmUyzGa0&callback=initMap');
    }
};

/**
 * Ajoute des script js a la page
 */

function appendScript( url,callback ){

    var script = document.createElement("script");
    script.type = "text/javascript";
    script.setAttribute('async','');
    script.setAttribute('defer','');
    if(callback)script.onload=callback;
    document.getElementsByTagName("head")[0].appendChild(script);
    script.src = url;

};


/*
 *  add_marker
 */

function add_marker( marker, map ) {

    var latlng = new google.maps.LatLng( marker.attr('data-lat'), marker.attr('data-lng') );

    // create marker
    var markerCreated = new google.maps.Marker({
                position	: latlng,
                map			: map,
                icon        : document.location.protocol+'//'+document.location.host+'/wp-content/themes/abbaye/assets/img/backend/POI.png'
    });

    // Push to Maps
    map.markers.push( markerCreated );

    // Center map Offset
    center_map(map);

};

/*
 *  remove_marker
 */

function remove_marker( marker, map ) {

    for (var i = 0; i < marker.length; i++) {
        marker[i].setMap(map);
    }

};


/*
 *  center_map
 */

function center_map( map ) {

    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each( map.markers, function( i, marker ){

        var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

        bounds.extend( latlng );

    });

    // only 1 marker?
    if( map.markers.length == 1 )
    {

        // set center of map
        map.setCenter( bounds.getCenter() );

    }
    else
    {
        // fit to bounds
        map.fitBounds( bounds );
    }

};