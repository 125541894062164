/*$('form').each(function(){
    $('input[data-frmval], textarea[data-frmval]',this).each(function(){
        if(this.getAttribute('type') != 'hidden'){
            if($(this).val() == $(this).data('frmval')){
                $(this).attr('placeholder',$(this).val()).val('');
            }
        }
    });

    $('select[data-frmval]').each(function(){
        $('option[value="0"]',this).text($(this).attr('data-frmval'));
    });
});*/