
var InitCheckbox = function(){



    $('.frm_forms input[type="radio"]').change(function(){

        $(this).parents('.frm_opt_container').find('.frm_radio').removeClass('checked-label');
        var c = this.checked ? '0' : '1';
        if( c == 0 ){ $(this).parents('.frm_radio').addClass('checked-label'); }

    });

    $('.frm_forms input[type="radio"]').each(function(){

        var c = this.checked ? '0' : '1';
        if( c == 0 ){ $(this).parents('.frm_radio').addClass('checked-label'); }

    });

    $('.frm_forms input[type="checkbox"]').change(function(){

        var c = this.checked ? '0' : '1';
        if( c == 0 ){ $(this).parents('.frm_checkbox').addClass('checked-label'); }
        else{ $(this).parents('.frm_checkbox').removeClass('checked-label'); }

    });

    $('.frm_forms input[type="checkbox"]').each(function(){

        var c = this.checked ? '0' : '1';
        if( c == 0 ){ $(this).parents('.frm_checkbox').addClass('checked-label'); }

    });

};