
    /*

     --  Dépendance : Hammer.js  --

     */


    var owlSliderListing = function() {


        // ** Slider listing CMS

        var SliderTabListingCMS = [];

        $('.owl-carousel.slider-listing-cms').not('.owl-loaded').each(function ( index, element ) {

            if( jQuery(this).children('article').length > 1 ) {

                SliderTabListingCMS[index] = [];
                SliderTabListingCMS[index].dom = jQuery(this);
                SliderTabListingCMS[index].domControl = SliderTabListingCMS[index].dom.parents('.bloc-slider-listing-cms').find('.custom-controler');
                SliderTabListingCMS[index].dom.parents('.bloc-slider-listing-cms').removeClass('owl-not-loader');

                // Set Id to slider
                SliderId = SliderTabListingCMS[index].dom.attr('id');
                if( SliderId === undefined ) { SliderTabListingCMS[index].dom.attr('id', 'slider_' + new Date().valueOf() ); }

                SliderTabListingCMS[index].dom.on('initialized.owl.carousel', function(e){

                    // Deport controle
                    SliderTabListingCMS[index].domControl.html( SliderTabListingCMS[index].dom.find('.owl-controls').html() );

                    var owlTop = parseInt(  parseInt( SliderTabListingCMS[index].dom.offset().top ) - parseInt( SliderTabListingCMS[index].dom.parents('.bloc-slider-listing-cms').offset().top ) ) + 5;
                    var figHeight = parseInt( parseInt( SliderTabListingCMS[index].dom.find('article').eq(0).find('figure').css('height') ) - 80 );

                    SliderTabListingCMS[index].domControl.css('top', owlTop + 'px');
                    SliderTabListingCMS[index].domControl.find('.owl-prev, .owl-next').css('height', figHeight + 'px');

                    if( SliderTabListingCMS[index].dom.find('.owl-item').not('.active').length == 0 ){
                        SliderTabListingCMS[index].domControl.find('.owl-prev, .owl-next').hide();
                    }else{
                        SliderTabListingCMS[index].domControl.find('.owl-prev, .owl-next').show();
                    }

                });

                var args = {
                    nav: true,
                    dots: true,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: true,
                    loop: true,
                    margin: 20,
                    touchDrag: false,
                    autoWidth: false,
                    navText : ["",""],
                    responsive: {
                        0:{
                            items:1
                        },
                        600:{
                            items:2

                        },
                        850:{
                            items:3
                        },
                        1100:{
                            items:4
                        }
                    }
                };

                SliderTabListingCMS[ index ].dom.owlCarousel(args);

                SliderTabListingCMS[ index ].data = jQuery(this).data('owlCarousel');

                SliderTabListingCMS[ index ].dom.on('resize.owl.carousel', function (e) {
                    setTimeout(function () {

                        var owlTop = parseInt(  parseInt( SliderTabListingCMS[index].dom.offset().top ) - parseInt( SliderTabListingCMS[index].dom.parents('.bloc-slider-listing-cms').offset().top ) ) + 5;
                        var figHeight = parseInt( parseInt( SliderTabListingCMS[index].dom.find('article').eq(0).find('figure').css('height') ) - 80 );

                        SliderTabListingCMS[index].domControl.css('top', owlTop + 'px');
                        SliderTabListingCMS[index].domControl.find('.owl-prev, .owl-next').css('height', figHeight + 'px');

                        if( SliderTabListingCMS[index].dom.find('.owl-item').not('.active').length == 0 ){
                            SliderTabListingCMS[index].domControl.find('.owl-prev, .owl-next').hide();
                        }else{
                            SliderTabListingCMS[index].domControl.find('.owl-prev, .owl-next').show();
                        }

                        fixHeight();

                    }, 50);
                });

                // Init Hammer Js
                SliderTabListingCMS[ index ].myElement = document.getElementById( SliderTabListingCMS[ index ].dom.attr("id") );
                SliderTabListingCMS[ index ].swipe = new Hammer( SliderTabListingCMS[ index ].myElement , { direction: Hammer.DIRECTION_HORIZONTAL } );

                // listen to events...
                SliderTabListingCMS[ index ].swipe.on("swipe", function(ev) {

                    if( ev.pointerType == "touch" ){

                        if( ev.direction == 2 ){ // Next
                            SliderTabListingCMS[index].data.next();
                        }

                        if( ev.direction == 4 ){ // Prev
                            SliderTabListingCMS[index].data.prev();
                        }

                    }

                });

                SliderTabListingCMS[index].domControl.find('.owl-prev').on('click', function(){
                    SliderTabListingCMS[index].data.prev();
                });

                SliderTabListingCMS[index].domControl.find('.owl-next').on('click', function(){
                    SliderTabListingCMS[index].data.next();
                });

            }

        });



        // ** Slider listing de post

        var SliderTabListing = [];

        $('.owl-carousel.slider-listing-post').not('.owl-loaded').each(function ( index, element ) {

            if( jQuery(this).children('article').length > 1 ) {

                SliderTabListing[index] = [];
                SliderTabListing[index].dom = jQuery(this);
                SliderTabListing[index].domControl = SliderTabListing[index].dom.parents('.bloc-slider-listing-post').find('.custom-controler');
                SliderTabListing[index].dom.parents('.bloc-slider-listing-post').removeClass('owl-not-loader');

                // Set Id to slider
                SliderId = SliderTabListing[index].dom.attr('id');
                if( SliderId === undefined ) { SliderTabListing[index].dom.attr('id', 'slider_' + new Date().valueOf() ); }

                SliderTabListing[index].dom.on('initialized.owl.carousel', function(e){

                    // Deport controle
                    SliderTabListing[index].domControl.html( SliderTabListing[index].dom.find('.owl-controls').html() );

                    var owlTop = parseInt(  parseInt( SliderTabListing[index].dom.offset().top ) - parseInt( SliderTabListing[index].dom.parents('.bloc-slider-listing-post').offset().top ) ) + 40;
                    var figHeight = parseInt( parseInt( SliderTabListing[index].dom.find('article').eq(0).find('figure').css('height') ) - 80 );

                    SliderTabListing[index].domControl.css('top', owlTop + 'px');
                    SliderTabListing[index].domControl.find('.owl-prev, .owl-next').css('height', figHeight + 'px');

                    if( SliderTabListing[index].dom.find('.owl-item').not('.active').length == 0 ){
                        SliderTabListing[index].domControl.find('.owl-prev, .owl-next').hide();
                    }else{
                        SliderTabListing[index].domControl.find('.owl-prev, .owl-next').show();
                    }

                });

                var args = {
                    nav: true,
                    dots: true,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: true,
                    loop: true,
                    margin: 20,
                    touchDrag: false,
                    autoWidth: false,
                    navText : ["",""],
                    responsive: {
                        0:{
                            items:1
                        },
                        600:{
                            items:2

                        },
                        850:{
                            items:3
                        },
                        1100:{
                            items:4
                        }
                    }
                };

                SliderTabListing[ index ].dom.owlCarousel(args);

                SliderTabListing[ index ].data = jQuery(this).data('owlCarousel');

                SliderTabListing[ index ].dom.on('resize.owl.carousel', function (e) {
                    setTimeout(function () {

                        var owlTop = parseInt(  parseInt( SliderTabListing[index].dom.offset().top ) - parseInt( SliderTabListing[index].dom.parents('.bloc-slider-listing-post').offset().top ) ) + 40;
                        var figHeight = parseInt( parseInt( SliderTabListing[index].dom.find('article').eq(0).find('figure').css('height') ) - 80 );

                        SliderTabListing[index].domControl.css('top', owlTop + 'px');
                        SliderTabListing[index].domControl.find('.owl-prev, .owl-next').css('height', figHeight + 'px');

                        if( SliderTabListing[index].dom.find('.owl-item').not('.active').length == 0 ){
                            SliderTabListing[index].domControl.find('.owl-prev, .owl-next').hide();
                        }else{
                            SliderTabListing[index].domControl.find('.owl-prev, .owl-next').show();
                        }

                        fixHeight();

                    }, 50);
                });

                // Init Hammer Js
                SliderTabListing[ index ].myElement = document.getElementById( SliderTabListing[ index ].dom.attr("id") );
                SliderTabListing[ index ].swipe = new Hammer( SliderTabListing[ index ].myElement , { direction: Hammer.DIRECTION_HORIZONTAL } );

                // listen to events...
                SliderTabListing[ index ].swipe.on("swipe", function(ev) {

                    if( ev.pointerType == "touch" ){

                        if( ev.direction == 2 ){ // Next
                            SliderTabListing[index].data.next();
                        }

                        if( ev.direction == 4 ){ // Prev
                            SliderTabListing[index].data.prev();
                        }

                    }

                });

                SliderTabListing[index].domControl.find('.owl-prev').on('click', function(){
                    SliderTabListing[index].data.prev();
                });

                SliderTabListing[index].domControl.find('.owl-next').on('click', function(){
                    SliderTabListing[index].data.next();
                });

            }

        });



        // ** Slider listing de salles

        var SliderTabListingRooms = [];

        $('.owl-carousel.slider-listing-rooms').not('.owl-loaded').each(function ( index, element ) {

            if( jQuery(this).children('article').length > 1 ) {

                SliderTabListingRooms[index] = [];
                SliderTabListingRooms[index].dom = jQuery(this);
                SliderTabListingRooms[index].domControl = SliderTabListingRooms[index].dom.parents('.bloc-slider-listing-room').find('.custom-controler');
                SliderTabListingRooms[index].dom.parents('.bloc-slider-listing-room').removeClass('owl-not-loader');

                // Set Id to slider
                SliderId = SliderTabListingRooms[index].dom.attr('id');
                if( SliderId === undefined ) { SliderTabListingRooms[index].dom.attr('id', 'slider_' + new Date().valueOf() ); }

                SliderTabListingRooms[index].dom.on('initialized.owl.carousel', function(e){

                    // Deport controle
                    SliderTabListingRooms[index].domControl.html( SliderTabListingRooms[index].dom.find('.owl-controls').html() );

                    var owlFigureHeight = parseInt( SliderTabListingRooms[index].dom.find('.owl-item .fixTo').eq(0).height() ) - 85;
                    if( owlFigureHeight > 140 ){ owlFigureHeight = 140; }
                    var owlFigureWidth = parseInt( owlFigureHeight * 1.69 );
                    SliderTabListingRooms[index].dom.find('.pop-up-box').css({ height : owlFigureHeight + 'px', width : owlFigureWidth + 'px', left : parseInt( parseInt(owlFigureWidth / 2) - 15 ) * -1 + 'px'  });

                    SliderTabListingRooms[index].dom.find('.owl-item').find('.rooms-listing').removeClass('decalmap');
                    SliderTabListingRooms[index].dom.find('.owl-item.active').last().find('.rooms-listing').addClass('decalmap');

                    var owlTop = parseInt(  parseInt( SliderTabListingRooms[index].dom.offset().top ) - parseInt( SliderTabListingRooms[index].dom.parents('.bloc-slider-listing-room').offset().top ) ) + 15;
                    var figHeight = parseInt( parseInt( SliderTabListingRooms[index].dom.find('article').eq(0).find('figure').css('height') ) - 30 );

                    SliderTabListingRooms[index].domControl.css('top', owlTop + 'px');
                    SliderTabListingRooms[index].domControl.find('.owl-prev, .owl-next').css('height', figHeight + 'px');

                    if( SliderTabListingRooms[index].dom.find('.owl-item').not('.active').length == 0 ){
                        SliderTabListingRooms[index].domControl.find('.owl-prev, .owl-next').hide();
                    }else{
                        SliderTabListingRooms[index].domControl.find('.owl-prev, .owl-next').show();
                    }

                });

                var args = {
                    nav: true,
                    dots: true,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: true,
                    loop: true,
                    margin: 20,
                    touchDrag: false,
                    autoWidth: false,
                    navText : ["",""],
                    responsive: {
                        0:{
                            items:1
                        },
                        650:{
                            items:2

                        },
                        750:{
                            items:2
                        },
                        950:{
                            items:3
                        }
                    }
                };

                SliderTabListingRooms[ index ].dom.owlCarousel(args);

                SliderTabListingRooms[ index ].data = jQuery(this).data('owlCarousel');

                SliderTabListingRooms[index].dom.on('resize.owl.carousel', function (e) {
                    setTimeout(function () {

                        var owlTop = parseInt(  parseInt( SliderTabListingRooms[index].dom.offset().top ) - parseInt( SliderTabListingRooms[index].dom.parents('.bloc-slider-listing-room').offset().top ) ) + 15;
                        var figHeight = parseInt( parseInt( SliderTabListingRooms[index].dom.find('article').eq(0).find('figure').css('height') ) - 30 );

                        var owlFigureHeight = parseInt( SliderTabListingRooms[index].dom.find('.owl-item .fixTo').eq(0).height() ) - 85;
                        if( owlFigureHeight > 140 ){ owlFigureHeight = 140; }
                        var owlFigureWidth = parseInt( owlFigureHeight * 1.69 );
                        SliderTabListingRooms[index].dom.find('.pop-up-box').css({ height : owlFigureHeight + 'px', width : owlFigureWidth + 'px', left : parseInt( parseInt(owlFigureWidth / 2) - 15 ) * -1 + 'px'  });

                        SliderTabListingRooms[index].domControl.css('top', owlTop + 'px');
                        SliderTabListingRooms[index].domControl.find('.owl-prev, .owl-next').css('height', figHeight + 'px');

                        if( SliderTabListingRooms[index].dom.find('.owl-item').not('.active').length == 0 ){
                            SliderTabListingRooms[index].domControl.find('.owl-prev, .owl-next').hide();
                        }else{
                            SliderTabListingRooms[index].domControl.find('.owl-prev, .owl-next').show();
                        }

                        fixHeight();

                    }, 50);
                });

                SliderTabListingRooms[index].dom.on('changed.owl.carousel', function (e) {
                    setTimeout(function () {

                        SliderTabListingRooms[index].dom.find('.owl-item').find('.rooms-listing').removeClass('decalmap');
                        SliderTabListingRooms[index].dom.find('.owl-item.active').find('.rooms-listing').last().addClass('decalmap');

                    }, 50);
                });

                // Init Hammer Js
                SliderTabListingRooms[ index ].myElement = document.getElementById( SliderTabListingRooms[ index ].dom.attr("id") );
                SliderTabListingRooms[ index ].swipe = new Hammer( SliderTabListingRooms[ index ].myElement , { direction: Hammer.DIRECTION_HORIZONTAL } );

                // listen to events...
                SliderTabListingRooms[ index ].swipe.on("swipe", function(ev) {

                    if( ev.pointerType == "touch" ){

                        if( ev.direction == 2 ){ // Next
                            SliderTabListingRooms[index].data.next();
                        }

                        if( ev.direction == 4 ){ // Prev
                            SliderTabListingRooms[index].data.prev();
                        }

                    }

                });

                SliderTabListingRooms[index].domControl.find('.owl-prev').on('click', function(){
                    SliderTabListingRooms[index].data.prev();
                });

                SliderTabListingRooms[index].domControl.find('.owl-next').on('click', function(){
                    SliderTabListingRooms[index].data.next();
                });

            }

        });



        // ** Slider Room

        var SliderRoomTab = [];

        $('.owl-carousel.slider-listing-room').not('.owl-loaded').each(function ( index, element ) {

            if( jQuery(this).children('.item').length > 0 ) {

                SliderRoomTab[index] = [];
                SliderRoomTab[index].dom = jQuery(this);
                SliderRoomTab[index].domControl = SliderRoomTab[index].dom.parents('.bloc-slider-listing-post').find('.custom-controler');


                // Set Id to slider
                SliderId = SliderRoomTab[index].dom.attr('id');
                if( SliderId === undefined ) { SliderRoomTab[index].dom.attr('id', 'slider_' + new Date().valueOf() ); }

                SliderRoomTab[index].dom.on('initialized.owl.carousel', function(e){

                    // Deport controle
                    SliderRoomTab[index].domControl.html( SliderRoomTab[index].dom.find('.owl-controls').html() );
                    SliderRoomTab[index].domControl.find('.owl-dots').insertAfter( SliderRoomTab[index].domControl.find('.owl-prev') );

                    SliderRoomTab[index].domControl.find('.owl-dot').each(function ( index, element ) {
                        $(this).find('span').text(index + 1);
                    });

                    var totalItems = SliderRoomTab[index].dom.find('.owl-dots').find('.owl-dot').length;
                    var currentItem = SliderRoomTab[ index ].dom.find('.owl-dots').find('.owl-dot.active').index() + 1;

                    SliderRoomTab[index].domControl.find('.owl-dot').removeClass('active');
                    SliderRoomTab[index].domControl.find('.owl-dot').not('.cloned').eq(currentItem-1).addClass('active');

                    SliderRoomTab[index].dom.find('.item figure').each(function ( index, element ) {
                        $(this).append('<span class="dataimg"><span class="icon-superficie"></span>' + $(this).attr("data-superficie") + '</span>');
                    });

                    if( SliderRoomTab[index].dom.find('.owl-item').length < 2 ) {
                        SliderRoomTab[index].domControl.remove();
                    }

                });

                var args = {
                    items: 1,
                    nav: true,
                    dots: true,
                    margin:40,
                    autoplay: false,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: false,
                    loop: false,
                    touchDrag: false,
                    autoWidth: false,
                    navText : [ '<span>' + SliderRoomTab[index].dom.attr('data-text-prev') + '</span>' , '<span>' + SliderRoomTab[index].dom.attr('data-text-next') + '</span>' ],
                    autoHeight: true
                };

                SliderRoomTab[ index ].dom.owlCarousel(args);
                SliderRoomTab[ index ].data = jQuery(this).data('owlCarousel');

                SliderRoomTab[index].dom.on('changed.owl.carousel', function (e) {
                    setTimeout(function () {

                         var totalItems = SliderRoomTab[index].dom.find('.owl-dots').find('.owl-dot').length;
                         var currentItem = SliderRoomTab[ index ].dom.find('.owl-dots').find('.owl-dot.active').index() + 1;

                         SliderRoomTab[index].domControl.find('.owl-dot').removeClass('active');
                         SliderRoomTab[index].domControl.find('.owl-dot').not('.cloned').eq(currentItem-1).addClass('active');

                    }, 50);
                });

                SliderRoomTab[index].domControl.find('.owl-prev').on('click', function(){
                    SliderRoomTab[index].data.prev();
                });

                SliderRoomTab[index].domControl.find('.owl-next').on('click', function(){
                    SliderRoomTab[index].data.next();
                });


                // Pour support tactile
                if( $("html.desktop").length > 0 ){

                    SliderRoomTab[index].dom.find('.room-listing').on( 'mouseenter', function(){

                        var imgURL = $(this).attr("data-room");
                        var imgLEVEL = $(this).attr("data-level");
                        if( imgLEVEL == 0 ){ imgLEVEL = 1; }else{ imgLEVEL = 0; }
                        var imgDom = SliderRoomTab[index].dom.parents('.bloc-slider-listing-post').find('.half-figure figure').eq( imgLEVEL ).find('img.room_hover');

                        imgDom.removeClass('show').attr('src',imgURL).addClass('show');

                    });

                    SliderRoomTab[index].dom.find('.room-listing').on( 'mouseleave', function(){

                        var imgLEVEL = $(this).attr("data-level");
                        if( imgLEVEL == 0 ){ imgLEVEL = 1; }else{ imgLEVEL = 0; }
                        var imgDom = SliderRoomTab[index].dom.parents('.bloc-slider-listing-post').find('.half-figure figure').eq( imgLEVEL ).find('img.room_hover');

                        imgDom.removeClass('show');

                    });

                }else{

                    SliderRoomTab[index].dom.find('.room-listing').on( 'click', function(e){

                        if ( parseInt($(window).innerWidth()) > 850 ) {

                            if( !$(this).hasClass('opened-menu') ){

                                e.preventDefault();
                                e.stopPropagation();

                                SliderRoomTab[index].dom.find('.room-listing').not(this).removeClass('opened-menu');
                                $(this).toggleClass('opened-menu');

                                var imgURL = $(this).attr("data-room");
                                var imgLEVEL = $(this).attr("data-level");
                                if( imgLEVEL == 0 ){ imgLEVEL = 1; }else{ imgLEVEL = 0; }
                                var imgDom = SliderRoomTab[index].dom.parents('.bloc-slider-listing-post').find('.half-figure figure').eq( imgLEVEL ).find('img.room_hover');

                                imgDom.removeClass('show').attr('src',imgURL).addClass('show');

                            }

                        }

                    });

                }

            }

        });



        // ** Slider

        var SliderPageTab = [];

        $('.owl-carousel.slider').not('.owl-loaded').each(function ( index, element ) {

            if( jQuery(this).children('.item').length > 1 ) {

                SliderPageTab[index] = [];
                SliderPageTab[index].dom = jQuery(this);
                SliderPageTab[index].domControl = SliderPageTab[index].dom.parents('.bloc-slider').find('.custom-controler');
                SliderPageTab[index].domControlDots = SliderPageTab[index].dom.parents('.bloc-slider').find('.custom-controler-dots');

                // Set Id to slider
                SliderId = SliderPageTab[index].dom.attr('id');
                if( SliderId === undefined ) { SliderPageTab[index].dom.attr('id', 'slider_' + new Date().valueOf() ); }

                SliderPageTab[index].dom.on('initialized.owl.carousel', function(e){

                    // Deport controle
                    SliderPageTab[index].domControl.prepend( SliderPageTab[index].dom.find('.owl-controls').html() );
                    SliderPageTab[index].domControlDots.prepend( SliderPageTab[index].dom.find('.owl-controls').html() );

                    // Initialisation des Nav
                    SliderPageTab[index].domControlDots.find('.owl-nav .owl-prev').after('<span class="owl-data"></span>');

                    // Initialisation des dots
                    SliderPageTab[index].domControlDots.find('.owl-dots').find('.owl-dot').wrapAll( '<div class="dots-inner"></div>' );
                    SliderPageTab[index].domControlDots.find('.owl-dots').find('.owl-dot').each(function ( i, e ) {
                        $(this).css('background-image','url("' + SliderPageTab[index].dom.find('.owl-item:not(.cloned)').eq(i).find('.item figure').attr('data-vign') +'")');
                    });

                    // Largeur des dots
                    var bloc_width =  parseInt( SliderPageTab[index].domControlDots.find('.owl-dots').width() ) / 3;
                    SliderPageTab[index].domControlDots.find('.owl-dots').find('.owl-dot').css('width', bloc_width + 'px');
                    SliderPageTab[index].domControlDots.find('.dots-inner').css('width', bloc_width * parseInt( SliderPageTab[index].domControlDots.find('.owl-dots').find('.owl-dot').length ) + 'px');

                    var totalItems = SliderPageTab[index].dom.find('.owl-dots').find('.owl-dot').length;
                    var currentItem = SliderPageTab[ index ].dom.find('.owl-dots').find('.owl-dot.active').index() + 1;

                    // Initialisation des Nav
                    SliderPageTab[index].domControlDots.find('.owl-nav .owl-data').html( currentItem + '/' + totalItems );

                    SliderPageTab[index].domControlDots.find('.owl-dot').removeClass('active');
                    SliderPageTab[index].domControlDots.find('.owl-dot').not('.cloned').eq(currentItem-1).addClass('active');

                    var figHeight = parseInt( parseInt( SliderPageTab[index].dom.find('.item').eq(0).css('height') ) - 80 );
                    if( figHeight > 300 ){ figHeight = 300; }

                    var owlTop = parseInt( parseInt( parseInt( SliderPageTab[index].dom.find('.item').eq(0).css('height') ) - figHeight ) / 2 ) + 40;

                    SliderPageTab[index].domControl.css('top', owlTop + 'px');
                    SliderPageTab[index].domControl.find('.owl-prev, .owl-next').css('height', figHeight + 'px');

                });

                var args = {
                    items: 1,
                    nav: true,
                    dots: true,
                    margin:0,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: true,
                    loop: true,
                    touchDrag: false,
                    autoWidth: false,
                    navText : ["",""],
                    autoHeight: true
                };

                SliderPageTab[ index ].dom.owlCarousel(args);
                SliderPageTab[ index ].data = jQuery(this).data('owlCarousel');

                SliderPageTab[index].dom.on('changed.owl.carousel', function (e) {
                    setTimeout(function () {

                        var totalItems = SliderPageTab[index].dom.find('.owl-dots').find('.owl-dot').length;
                        var currentItem = SliderPageTab[ index ].dom.find('.owl-dots').find('.owl-dot.active').index() + 1;

                        // Initialisation des Nav
                        SliderPageTab[index].domControlDots.find('.owl-nav .owl-data').html( currentItem + '/' + totalItems );

                        SliderPageTab[index].domControlDots.find('.owl-dot').removeClass('active');
                        SliderPageTab[index].domControlDots.find('.owl-dot').eq(currentItem-1).addClass('active');

                        if( ( currentItem - 1 ) < ( parseInt( SliderPageTab[index].domControlDots.find('.owl-dots').find('.owl-dot').length ) - 2 ) ){

                            var bloc_width =  parseInt( SliderPageTab[index].domControlDots.find('.owl-dots').width() ) / 3;
                            var offestLeft = (currentItem-1) * bloc_width;
                            SliderPageTab[index].domControlDots.find('.dots-inner').css('margin-left', '-' + offestLeft + 'px');

                        }else{

                            var bloc_width =  parseInt( SliderPageTab[index].domControlDots.find('.owl-dots').width() ) / 3;
                            var offestLeft = ( parseInt( SliderPageTab[index].domControlDots.find('.owl-dots').find('.owl-dot').length ) - 3 ) * bloc_width;
                            SliderPageTab[index].domControlDots.find('.dots-inner').css('margin-left', '-' + offestLeft + 'px');

                        }

                    }, 50);
                });

                SliderPageTab[index].dom.on('resize.owl.carousel', function (e) {
                    setTimeout(function () {

                        var figHeight = parseInt( parseInt( SliderPageTab[index].dom.find('.item').eq(0).css('height') ) - 80 );
                        if( figHeight > 300 ){ figHeight = 300; }

                        var owlTop = parseInt( parseInt( parseInt( SliderPageTab[index].dom.find('.item').eq(0).css('height') ) - figHeight ) / 2 ) + 40;

                        SliderPageTab[index].domControl.css('top', owlTop + 'px');
                        SliderPageTab[index].domControl.find('.owl-prev, .owl-next').css('height', figHeight + 'px');

                        // Largeur des dots
                        var bloc_width =  parseInt( SliderPageTab[index].domControlDots.find('.owl-dots').width() ) / 3;
                        SliderPageTab[index].domControlDots.find('.owl-dots').find('.owl-dot').css('width', bloc_width + 'px');
                        SliderPageTab[index].domControlDots.find('.dots-inner').css('width', bloc_width * parseInt( SliderPageTab[index].domControlDots.find('.owl-dots').find('.owl-dot').length ) + 'px');

                    }, 50);
                });

                // Init Hammer Js
                SliderPageTab[ index ].myElement = document.getElementById( SliderPageTab[ index ].dom.attr("id") );
                SliderPageTab[ index ].swipe = new Hammer( SliderPageTab[ index ].myElement , { direction: Hammer.DIRECTION_HORIZONTAL } );

                // listen to events...
                SliderPageTab[ index ].swipe.on("swipe", function(ev) {

                    if( ev.pointerType == "touch" ){

                        if( ev.direction == 2 ){ // Next
                            SliderPageTab[index].data.next();
                        }

                        if( ev.direction == 4 ){ // Prev
                            SliderPageTab[index].data.prev();
                        }

                    }

                });

                SliderPageTab[index].domControl.find('.owl-prev').on('click', function(){
                    SliderPageTab[index].data.prev();
                });

                SliderPageTab[index].domControl.find('.owl-next').on('click', function(){
                    SliderPageTab[index].data.next();
                });

                SliderPageTab[index].domControlDots.find('.owl-dot').on('click', function(){

                    var ClickItems = SliderPageTab[index].domControlDots.find('.owl-dots').find('.owl-dot').index( $(this) );
                    SliderPageTab[index].dom.trigger('to.owl.carousel', ClickItems);

                });

            }

        });



        // ** Slider Homepage

        var SliderTabHome = [];

        $('.owl-carousel.slider-homepage').not('.owl-loaded').each(function ( index, element ) {

            if( jQuery(this).children('.item').length > 0 ) {

                SliderTabHome[index] = [];
                SliderTabHome[index].dom = jQuery(this);
                SliderTabHome[index].domControl = SliderTabHome[index].dom.parents('.bloc-slider-homepage').find('.custom-controler');

                // Set Id to slider
                SliderId = SliderTabHome[index].dom.attr('id');
                if( SliderId === undefined ) { SliderTabHome[index].dom.attr('id', 'slider_' + new Date().valueOf() ); }

                // Fix à la hauteur de l'écran
                if( parseInt( $(window).width() ) > 650 ){
                    var screenHeight = parseInt( $(window).height() ) + 1;
                    SliderTabHome[index].dom.find('.item, figure, figure > img').css('height', parseInt(screenHeight) + "px" );
                }else{
                    SliderTabHome[index].dom.find('.item, figure, figure > img').css('height', "auto" );
                }

                SliderTabHome[index].dom.on('initialized.owl.carousel', function(e){

                    var maxHeight = 0;

                    // Deport controle
                    SliderTabHome[index].domControl.prepend( SliderTabHome[index].dom.find('.owl-controls').html() );

                    // Initialisation des Nav
                    SliderTabHome[index].domControl.find('.owl-nav .owl-prev').after('<span class="owl-data"></span>');

                    // Initialisation des dots
                    SliderTabHome[index].domControl.find('.owl-dots').find('.owl-dot').each(function ( i, e ) {
                        $(this).css('background-image','url("' + SliderTabHome[index].dom.find('.owl-item:not(.cloned)').eq(i).find('.item figure').attr('data-vign') +'")');
                        $(this).html("<span><span>"+ SliderTabHome[index].dom.find('.owl-item:not(.cloned)').eq(i).find('.item').attr('data-dottext') +"</span></span>");
                    });

                    // Initialisation de la time bar
                    SliderTabHome[index].domControl.find('.time-bar > span').addClass('transition').css('width','100%');

                    // Fix à la hauteur de l'écran
                    if( parseInt( $(window).width() ) > 650 ){
                        var screenHeight = parseInt( $(window).height() ) + 1;
                        SliderTabHome[index].dom.find('.container').css('height', "auto" );
                        SliderTabHome[index].dom.find('.owl-stage-outer, .owl-stage, .owl-item, .item, figure, figure > img').css('height', parseInt(screenHeight) + "px" );
                    }else{

                        SliderTabHome[index].dom.find('.owl-stage-outer, .owl-stage, .owl-item, .item, figure, figure > img').css('height', "auto" );

                        SliderTabHome[index].dom.find('.container').css('height', "auto" );
                        SliderTabHome[index].dom.find('.container').each(function ( index, element ) {
                            if( maxHeight < parseInt( $(this).outerHeight() ) ){ maxHeight = parseInt( $(this).outerHeight() ); }
                        });
                        SliderTabHome[index].dom.find('.container').css('height', maxHeight + 'px' );

                    }

                    var totalItems = SliderTabHome[index].dom.find('.owl-dots').find('.owl-dot').length;
                    var currentItem = SliderTabHome[ index ].dom.find('.owl-dots').find('.owl-dot.active').index() + 1;

                    // Initialisation des Nav
                    SliderTabHome[index].domControl.find('.owl-nav .owl-data').html( currentItem + '/' + totalItems );

                    SliderTabHome[index].domControl.find('.owl-dot').removeClass('active');
                    SliderTabHome[index].domControl.find('.owl-dot').not('.cloned').eq(currentItem-1).addClass('active');

                });

                var args = {
                    items: 1,
                    nav: true,
                    dots: true,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: false,
                    loop: true,
                    touchDrag: false,
                    autoWidth: false,
                    navText : ["",""],
                    autoHeight: false
                };

                SliderTabHome[ index ].dom.owlCarousel(args);
                SliderTabHome[ index ].data = jQuery(this).data('owlCarousel');

                SliderTabHome[index].dom.on('changed.owl.carousel', function (e) {
                    setTimeout(function () {

                        // Gestion de la time bar
                        SliderTabHome[index].domControl.find('.time-bar > span')
                            .removeClass('transition').css('width','0%')
                            .wait(600)
                            .addClass('transition').css('width','100%');

                        var totalItems = SliderTabHome[index].dom.find('.owl-dots').find('.owl-dot').length;
                        var currentItem = SliderTabHome[ index ].dom.find('.owl-dots').find('.owl-dot.active').index() + 1;

                        // Initialisation des Nav
                        SliderTabHome[index].domControl.find('.owl-nav .owl-data').html( currentItem + '/' + totalItems );

                        SliderTabHome[index].domControl.find('.owl-dot').removeClass('active');
                        SliderTabHome[index].domControl.find('.owl-dot').eq(currentItem-1).addClass('active');

                    }, 50);
                });

                SliderTabHome[index].dom.on('resize.owl.carousel', function (e) {
                    setTimeout(function () {

                        var maxHeight = 0;

                        // Fix à la hauteur de l'écran
                        if( parseInt( $(window).width() ) > 650 ){
                            var screenHeight = parseInt( $(window).height() ) + 1;
                            SliderTabHome[index].dom.find('.container').css('height', "auto" );
                            SliderTabHome[index].dom.find('.owl-stage-outer, .owl-stage, .owl-item, .item, figure, figure > img').css('height', parseInt(screenHeight) + "px" );
                        }else{

                            SliderTabHome[index].dom.find('.owl-stage-outer, .owl-stage, .owl-item, .item, figure, figure > img').css('height', "auto" );

                            SliderTabHome[index].dom.find('.container').css('height', "auto" );
                            SliderTabHome[index].dom.find('.container').each(function ( index, element ) {
                                if( maxHeight < parseInt( $(this).outerHeight() ) ){ maxHeight = parseInt( $(this).outerHeight() ); }
                            });
                            SliderTabHome[index].dom.find('.container').css('height', maxHeight + 'px' );

                        }

                    }, 50);
                });

                // Init Hammer Js
                SliderTabHome[ index ].myElement = document.getElementById( SliderTabHome[ index ].dom.attr("id") );
                SliderTabHome[ index ].swipe = new Hammer( SliderTabHome[ index ].myElement , { direction: Hammer.DIRECTION_HORIZONTAL } );

                // listen to events...
                SliderTabHome[ index ].swipe.on("swipe", function(ev) {

                    if( ev.pointerType == "touch" ){

                        if( ev.direction == 2 ){ // Next
                            SliderTabHome[index].data.next();
                        }

                        if( ev.direction == 4 ){ // Prev
                            SliderTabHome[index].data.prev();
                        }

                    }

                });

                SliderTabHome[index].domControl.find('.owl-prev').on('click', function(){
                    SliderTabHome[index].data.prev();
                });

                SliderTabHome[index].domControl.find('.owl-next').on('click', function(){
                    SliderTabHome[index].data.next();
                });

                SliderTabHome[index].domControl.find('.owl-dot').on('click', function(){

                    var ClickItems = SliderTabHome[index].domControl.find('.owl-dots').find('.owl-dot').index( $(this) );
                    SliderTabHome[index].dom.trigger('to.owl.carousel', ClickItems);

                });

            }

        });


        // ** Slider Events

        var SliderTabEvents = [];

        $('.owl-carousel.slider-events').not('.owl-loaded').each(function ( index, element ) {

            if( jQuery(this).children('.item').length > 0 ) {

                SliderTabEvents[index] = [];
                SliderTabEvents[index].dom = jQuery(this);
                SliderTabEvents[index].domControl = SliderTabEvents[index].dom.parents('.bloc-slider-events').find('.custom-controler');

                // Set Id to slider
                SliderId = SliderTabEvents[index].dom.attr('id');
                if( SliderId === undefined ) { SliderTabEvents[index].dom.attr('id', 'slider_' + new Date().valueOf() ); }

                // Fix à la hauteur de l'écran
                if( parseInt( $(window).width() ) > 650 ){
                    var screenHeight = parseInt( $(window).height() ) + 1;
                    SliderTabEvents[index].dom.find('.item, figure, figure > img').css('height', parseInt(screenHeight) + "px" );
                }else{
                    SliderTabEvents[index].dom.find('.item, figure, figure > img').css('height', "auto" );
                }

                SliderTabEvents[index].dom.on('initialized.owl.carousel', function(e){

                    var maxHeightEvent = 0;

                    // Deport controle
                    SliderTabEvents[index].domControl.prepend( SliderTabEvents[index].dom.find('.owl-controls').html() );

                    // Initialisation des Nav
                    SliderTabEvents[index].domControl.find('.owl-nav .owl-prev').after('<span class="owl-data"></span>');

                    // Fix à la hauteur de l'écran
                    if( parseInt( $(window).width() ) > 650 ){
                        var screenHeight = parseInt( $(window).height() ) + 1;
                        SliderTabEvents[index].dom.find('.container').css('height', "auto" );
                        SliderTabEvents[index].dom.find('.owl-stage-outer, .owl-stage, .owl-item, .item').css('height', parseInt(screenHeight) + "px" );
                    }else{

                        SliderTabEvents[index].dom.find('.owl-stage-outer, .owl-stage, .owl-item, .item').css('height', "auto" );

                        SliderTabEvents[index].dom.find('.container').css('height', "auto" );
                        SliderTabEvents[index].dom.find('.container').each(function ( index, element ) {
                            if( maxHeightEvent < parseInt( $(this).outerHeight() ) ){ maxHeightEvent = parseInt( $(this).outerHeight() ); }
                        });
                        SliderTabEvents[index].dom.find('.container').css('height', maxHeightEvent + 'px' );

                    }

                    var totalItems = SliderTabEvents[index].dom.find('.owl-dots').find('.owl-dot').length;
                    var currentItem = SliderTabEvents[ index ].dom.find('.owl-dots').find('.owl-dot.active').index() + 1;

                    // Initialisation des Nav
                    SliderTabEvents[index].domControl.find('.owl-nav .owl-data').html( currentItem + '/' + totalItems );

                    SliderTabEvents[index].domControl.find('.owl-dot').removeClass('active');
                    SliderTabEvents[index].domControl.find('.owl-dot').not('.cloned').eq(currentItem-1).addClass('active');

                });

                var args = {
                    items: 1,
                    nav: true,
                    dots: true,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: false,
                    loop: true,
                    touchDrag: false,
                    autoWidth: false,
                    navText : ["",""],
                    autoHeight: false
                };

                if( $('html.touchscreen').length > 0 ){
                    args.autoplay = false;
                }

                SliderTabEvents[ index ].dom.owlCarousel(args);
                SliderTabEvents[ index ].data = jQuery(this).data('owlCarousel');

                SliderTabEvents[index].dom.on('changed.owl.carousel', function (e) {
                    setTimeout(function () {

                        var totalItems = SliderTabEvents[index].dom.find('.owl-dots').find('.owl-dot').length;
                        var currentItem = SliderTabEvents[ index ].dom.find('.owl-dots').find('.owl-dot.active').index() + 1;

                        // Initialisation des Nav
                        SliderTabEvents[index].domControl.find('.owl-nav .owl-data').html( currentItem + '/' + totalItems );

                        SliderTabEvents[index].domControl.find('.owl-dot').removeClass('active');
                        SliderTabEvents[index].domControl.find('.owl-dot').eq(currentItem-1).addClass('active');

                    }, 50);
                });

                SliderTabEvents[index].dom.on('resize.owl.carousel', function (e) {
                    setTimeout(function () {

                        var maxHeightEvent = 0;

                        // Fix à la hauteur de l'écran
                        if( parseInt( $(window).width() ) > 650 ){
                            var screenHeight = parseInt( $(window).height() ) + 1;
                            SliderTabEvents[index].dom.find('.container').css('height', "auto" );
                            SliderTabEvents[index].dom.find('.owl-stage-outer, .owl-stage, .owl-item, .item').css('height', parseInt(screenHeight) + "px" );
                        }else{

                            SliderTabEvents[index].dom.find('.owl-stage-outer, .owl-stage, .owl-item, .item').css('height', "auto" );

                            SliderTabEvents[index].dom.find('.container').css('height', "auto" );
                            SliderTabEvents[index].dom.find('.container').each(function ( index, element ) {
                                if( maxHeightEvent < parseInt( $(this).outerHeight() ) ){ maxHeightEvent = parseInt( $(this).outerHeight() ); }
                            });
                            SliderTabEvents[index].dom.find('.container').css('height', maxHeightEvent + 'px' );

                        }

                    }, 50);
                });

                // Init Hammer Js
                SliderTabEvents[ index ].myElement = document.getElementById( SliderTabEvents[ index ].dom.attr("id") );
                SliderTabEvents[ index ].swipe = new Hammer( SliderTabEvents[ index ].myElement , { direction: Hammer.DIRECTION_HORIZONTAL } );

                // listen to events...
                SliderTabEvents[ index ].swipe.on("swipe", function(ev) {

                    if( ev.pointerType == "touch" ){

                        if( ev.direction == 2 ){ // Next
                            SliderTabEvents[index].data.next();
                        }

                        if( ev.direction == 4 ){ // Prev
                            SliderTabEvents[index].data.prev();
                        }

                    }

                });

                SliderTabEvents[index].domControl.find('.owl-prev').on('click', function(){
                    SliderTabEvents[index].data.prev();
                });

                SliderTabEvents[index].domControl.find('.owl-next').on('click', function(){
                    SliderTabEvents[index].data.next();
                });

                SliderTabEvents[index].domControl.find('.owl-dot').on('click', function(){

                    var ClickItems = SliderTabEvents[index].domControl.find('.owl-dots').find('.owl-dot').index( $(this) );
                    SliderTabEvents[index].dom.trigger('to.owl.carousel', ClickItems);

                });

            }

        });


    };