
$(function() {


    var ajax_pass = function( elem ){

        var pass = elem.parents('.zipPassword-form').find('input[name=zipPassword]').val();
        if( pass.length > 0 ){

            elem.parents('.zipPassword-form.opened').addClass('charged');

            jQuery.post( object_ajaxurl.ajaxurl, {
                    'action' : 'get_zip_link',
                    'pass' : pass,
                    'id' : elem.parents('.zipPassword-form').find('.input-sufix').attr('data-id'),
                    'name' : elem.parents('.zipPassword-form').find('.input-sufix').attr('data-name')

                },
                function(response){

                    if( response != 'error' ){

                        elem.parents('.bloc-gallery').find('.message').removeClass('show');
                        elem.parents('.zipPassword-form-wrapper').html('<p>' + elem.parents(".zipPassword-form").find(".input-sufix").attr("data-msg") + ' :  <a target="_blank" href="'+response+'">'+response+'</a></p>');

                    }else{

                        elem.parents('.bloc-gallery').find('.message').addClass('show');

                    }

                    elem.parents('.zipPassword-form.opened').removeClass('charged');

                }
            );

        }

    };


    // Ouverture menu du mot de passe

    $(".ajax_zip_link").on("click", function(){
        $(this).parent().find('.zipPassword-form').toggleClass('opened');
        $(this).parents('.bloc-gallery').find('.message').removeClass('show');

        if( $(this).parent().find('.zipPassword-form').hasClass('opened') ){
            $(this).parent().find('input[name=zipPassword]').focus()
        }

    });

    // Vérification du mot de passe

    $(document).on("click", ".zipPassword-form.opened .input-sufix", function(){
        ajax_pass( $(this) );
    });

    $(document).on("keypress", ".zipPassword-form.opened input[name=zipPassword]", function(event){
        if(event.which == 10 || event.which == 13) {
            ajax_pass( $(this) );
        }
    });

    $("#ajax_relation_btn").on("click", function(){

        $(".ajax_relation_loader").show();

        //var PostToLoad = $("#ajax_call").attr('data-to-load');
        var maxPost = $("#ajax_call").attr('data-post-max');

        // Add New current Year
        var minYear = '0';
        $("#ajax_relation .event-listing.item").not('.is_not_passed').each(function ( index, element ) {
            if( minYear > parseInt( $(this).attr('data-item-year') ) || ( minYear == 0 && parseInt( $(this).attr('data-item-year') ) > 0 ) ){ minYear = parseInt( $(this).attr('data-item-year') ); }
        });

        var Years = minYear;
        //console.log(Years);

        var nbrPost = parseInt( $("#ajax_relation").find('.event-listing.item').length );

        if( nbrPost < maxPost ){

            jQuery.post( object_ajaxurl.ajaxurl, {
                    'action': 'get_next_events',
                    //'PostToLoad': PostToLoad,
                    'nbrPost' : nbrPost,
                    'Years' : Years
                },
                function(response){

                    $("#ajax_relation > .container").append(response);
                    $(".ajax_relation_loader").hide();

                    nbrPost = parseInt( $("#ajax_relation").find('.event-listing.item').length );
                    if( nbrPost >= maxPost ){

                        $("#ajax_relation_btn").hide();
                        $(".ajax_relation_loader").hide();

                    }

                }
            );

        }else{

            $("#ajax_relation_btn").hide();
            $(".ajax_relation_loader").hide();

        }

    });

});
