
    // -- Gestion menu touchscreen sur le menu version mobile -- //

    $(document).on( "click", "html.desktop #overlay-mobile-nav #menu-primary > li.menu-item-has-children a, html.desktop #overlay-mobile-nav #menu-primary > li.menu-item-has-children span", function( e ) {

        if( $(this).parent().find('.sub-menu').length > 0 ){

            if( !$(this).parent().hasClass('opened-menu') ){

                e.preventDefault();
                e.stopPropagation();

                $('html.desktop #overlay-mobile-nav #menu-primary > li a, html.desktop #overlay-mobile-nav #menu-primary > li span').not(this).parent().removeClass('opened-menu');
                $(this).parent().toggleClass('opened-menu');

            }

        }

    });

    $(document).on( "touchend", "html.touchscreen #overlay-mobile-nav #menu-primary > li.menu-item-has-children a, html.touchscreen #overlay-mobile-nav #menu-primary > li.menu-item-has-children span", function( e ) {

        if( $(this).parent().find('.sub-menu').length > 0 ){

            if( !$(this).parent().hasClass('opened-menu') ){

                e.preventDefault();
                e.stopPropagation();

                $('html.touchscreen #overlay-mobile-nav #menu-primary > li a, html.touchscreen #overlay-mobile-nav #menu-primary > li span').not(this).parent().removeClass('opened-menu');
                $(this).parent().toggleClass('opened-menu');

            }

        }

    });


    // -- Gestion menu touchscreen -- //

    $(document).on( "touchend", "html.touchscreen #header #menu-primary > li a, html.touchscreen #header #menu-primary > li span", function( e ) {

        if( $(this).parent().find('.sub-menu').length > 0 ){

            if( !$(this).parent().hasClass('opened-menu') ){

                e.preventDefault();
                e.stopPropagation();

                $('html.touchscreen #menu-primary > li a, html.touchscreen #menu-primary > li span').not(this).parent().removeClass('opened-menu');
                $(this).parent().toggleClass('opened-menu');

            }

        }

    });


    // Pour support tactile
    if( $("html.desktop").length > 0 ){


        // Ouverture et Fermeture du menu mobile

        $(document).on( "click", "html.desktop body.get-mobile-menu .menu__open", function(){

            $('body.get-mobile-menu').toggleClass('ytm-open');
            if( $('body.ytm-open').length > 0 ){ $('html').addClass('stop-scroll'); }else{ $('html').removeClass('stop-scroll'); }

        });

        $(document).on( "click", "html.desktop body.get-mobile-menu.ytm-open #main, html.desktop body.get-mobile-menu.ytm-open #header, html.desktop body.get-mobile-menu.ytm-open #colophon, html.desktop body.get-mobile-menu.ytm-open #overlay-mobile-nav", function(event){

            if( ! $( event.target ).hasClass('nolink') ){

                setTimeout(function(){

                    $('body.get-mobile-menu').removeClass('ytm-open');
                    $('html').removeClass('stop-scroll');

                }, 50);

            }else{ $( event.target ).parent().toggleClass('opened-menu'); }

        });

    }else{


        // Ouverture et Fermeture du menu de navigation mobile

        $(document).on( "touchend", "html.touchscreen body.get-mobile-menu .menu__open", function( e ){

            e.preventDefault();
            e.stopPropagation();

            $('body.get-mobile-menu').toggleClass('ytm-open');
            if( $('body.ytm-open').length > 0 ){ $('html').addClass('stop-scroll'); }else{ $('html').removeClass('stop-scroll'); }

        });

        $(document).on( "touchend", "html.touchscreen body.get-mobile-menu.ytm-open #main, html.touchscreen body.get-mobile-menu.ytm-open #header, html.touchscreen body.get-mobile-menu.ytm-open #colophon, html.touchscreen body.get-mobile-menu.ytm-open #overlay-mobile-nav", function(event){

            if( ! $( event.target ).hasClass('nolink') ){

                setTimeout(function(){

                    $('body.get-mobile-menu').removeClass('ytm-open');
                    $('html').removeClass('stop-scroll');

                }, 50);

            }else{ $( event.target ).parent().toggleClass('opened-menu'); }

        });

    }


    // -- Gestion du mobile Menu -- //

    var mobileMenu = function(){

        if ( parseInt($(window).innerWidth()) <= 950 ) {

            if( $('body.get-mobile-menu').length == 0 ){
                $('body').addClass('get-mobile-menu');
            }

        } else {

            if( $('body.get-mobile-menu').length > 0 ){

                $('body').removeClass('get-mobile-menu').removeClass('ytm-open');
                $('html').removeClass('stop-scroll');

            }

        }

    }; mobileMenu();


    /*   Aux changements d'orientation de la page
     * -------------------------------------------------- */

    if ( window.addEventListener ) {
        window.addEventListener("orientationchange", function () {

            // Fix pour tablettes
            setTimeout(function () {

                mobileMenu();

            }, 50);

        }, false);
    }

    // Store the window width
    var windowWidth2 = parseInt( $(window).width() );

    /*   Aux redimensionnements de la page
     * -------------------------------------------------- */
    $(window).resize(function(){

        // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
        if ($(window).width() != windowWidth2) {

            // Fix pour tablettes
            setTimeout(function () {

                mobileMenu();

                // Update the window width for next time
                windowWidth2 = $(window).width();

            }, 50);

        }

    });